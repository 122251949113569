import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BlogPost from "../components/blogPost";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Card, Breadcrumb } from "react-bootstrap";
import SocialMedia from "../components/socialMedia";

const uri = "/dutch-oven-cooking-tips/";

const DutchOvenCookingTipsPage = () => {
  const coal_headings = [
    {
      title: "Temp/Oven Size",
      temps: [
        { f: "325F", c: "163C" },
        { f: "350F", c: "177C" },
        { f: "375F", c: "191C" },
        { f: "400F", c: "204C" },
        { f: "425F", c: "218C" },
        { f: "450F", c: "232C" },
      ],
    },
  ];

  const coals = [
    {
      size: "8-inch",
      coals: [
        { t: 10, b: 5 },
        { t: 11, b: 5 },
        { t: 11, b: 6 },
        { t: 12, b: 6 },
        { t: 13, b: 6 },
        { t: 14, b: 6 },
      ],
    },
    {
      size: "10-inch",
      coals: [
        { t: 13, b: 6 },
        { t: 14, b: 7 },
        { t: 16, b: 7 },
        { t: 17, b: 8 },
        { t: 18, b: 9 },
        { t: 19, b: 10 },
      ],
    },
    {
      size: "12-inch",
      coals: [
        { t: 16, b: 7 },
        { t: 17, b: 8 },
        { t: 18, b: 9 },
        { t: 19, b: 10 },
        { t: 21, b: 10 },
        { t: 22, b: 11 },
      ],
    },
    {
      size: "14-inch",
      coals: [
        { t: 20, b: 10 },
        { t: 21, b: 11 },
        { t: 22, b: 12 },
        { t: 24, b: 12 },
        { t: 25, b: 13 },
        { t: 26, b: 14 },
      ],
    },
    {
      size: "16-inch",
      coals: [
        { t: 25, b: 12 },
        { t: 26, b: 13 },
        { t: 27, b: 14 },
        { t: 28, b: 15 },
        { t: 29, b: 16 },
        { t: 30, b: 17 },
      ],
    },
  ];

  return (
    <Layout pageInfo={{ pageName: "dutch-oven-cooking-tips2" }}>
      <Seo
        title="Dutch Oven Cooking Tips"
        keywords={[`recipes`, `browse`]}
        description="Great tips for cooking with a Dutch Oven"
        imageSrc="/dutch-oven-600.jpg"
        imageWidth="600"
        imageHeight="431"
        imageAlt="Dutch Oven"
        url={uri}
      />
      <Container fluid className="xxtext-center">
        <Row className="justify-content-left my-3">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Dutch Oven Cooking Tips</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <BlogPost title="Dutch Oven Cooking Tips" adCount={0}>
          <Container fluid className="lg-font">
            <Row className="justify-content-center d-flex flex-wrap pt-3 text-center">
              <p>
                Dutch oven camp cooking is the ultimate fun and flexibility! Keep reading here to understand the
                different types of Dutch Ovens, the number of coals to use for heating, and some other tips.
              </p>

              <div className="pb-2 pt-2 text-center" id="dutch-oven-tips-dutch-oven">
                <StaticImage src="../images/dutch-oven-600.jpg" alt="Dutch Oven" />
              </div>
              <div className="d-flex justify-content-center pb-4">
                <SocialMedia uri={"https://www.iloveitcamping.com" + uri} position="top" />
              </div>

              <p>
                It's not difficult to use a Dutch oven, but they are heavy, so it's probably not the best option for a
                canoe trip or a hike.
              </p>

              <p>
                For campfire cooking, the best way to heat your Dutch oven is to use hot coals. A 12 or 14 inch cast
                iron Dutch oven with a flat top is your best option (see photo below) where you can put coals on the top
                and bottom. The chart below will tell you how many hot coals to use to keep your Dutch oven at the right
                temperature. Simply heat the coals in the campfire and place them on or under your Dutch oven with
                tongues.
              </p>

              <p>
                The Dutch oven creates an oven environment at camp where you can bake cookies, cakes, rolls, etc. In
                some cases you will want to create an air gap between your cooking pan and the bottom of the Dutch oven,
                especially when baking cookies. To create an air gap, simply put small pebbles under the pan.
              </p>
              <p>
                You can also cook Dutch oven recipes at home in a standard oven by just putting it in the oven. In this
                case, a ceramic coated Dutch oven may work better, but almost any will work.
              </p>

              <p>Here are some different types of Dutch ovens:</p>
            </Row>

            <Row className="justify-content-center d-flex flex-wrap pt-4">
              <Card className="m-2 mb-3 dutch-oven-card">
                <Card.Body>
                  <Card.Title className="pb-1 dutch-oven-img-title">Cast Iron Dutch Oven</Card.Title>
                  <StaticImage src="../images/cast_iron_dutch_oven2.jpg" alt="Cast Iron Dutch Oven" />
                  <div className="pt-2 index-card-text">
                    <Card.Text>Best for campfire cooking</Card.Text>
                  </div>
                </Card.Body>
              </Card>

              <Card className="m-2 mb-3 dutch-oven-card">
                <Card.Body>
                  <Card.Title className="pb-1 dutch-oven-img-title">Ceramic Coated Cast Iron Dutch Oven</Card.Title>
                  <StaticImage
                    src="../images/ceramic_cast_iron_dutch_oven2.jpg"
                    alt="Ceramic Coated Cast Iron Dutch Oven"
                  />
                  <div className="pt-2 index-card-text">
                    <Card.Text>Works well in an oven</Card.Text>
                  </div>
                </Card.Body>
              </Card>

              <Card className="m-2 mb-3 dutch-oven-card">
                <Card.Body>
                  <Card.Title className="pb-1 dutch-oven-img-title">Aluminum Dutch Oven</Card.Title>
                  <StaticImage src="../images/aluminum_dutch_oven.jpg" alt="Aluminum Dutch Oven" />
                  <div className="pt-2 index-card-text">
                    <Card.Text>A lightweight option</Card.Text>
                  </div>
                </Card.Body>
              </Card>

              <Card className="m-2 mb-3 dutch-oven-card">
                <Card.Body>
                  <Card.Title className="pb-1 dutch-oven-img-title">2-in-1 Dutch Oven</Card.Title>
                  <StaticImage src="../images/dutch-oven-2-in-1b.jpg" alt="2-in-1 Dutch Oven" />
                  <div className="pt-2 index-card-text">
                    <Card.Text>Lid works as a pan too</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Row>
          </Container>

          <h4 className="pt-3 pb-3">
            <strong>How many coals (charcoal briquettes) do I need? </strong>
          </h4>

          <div className="d-flex">
            <div className="md-font">
              <p>
                The number of coals (charcoal briquettes) you need to get the right temperature depends on many factors.
                This is the fun part! You will need to add or remove coals and move them around to control the
                temperature. Here are some of the factors that will come into play:
              </p>
              <ul>
                <li>Air temperature - use more coals in colder weather</li>
                <li>Shade/sun - use fewer coals in the sun, more coals in the shade</li>
                <li>Size of Dutch oven - use more coals for a bigger oven</li>
                <li>Amount of food in the oven - you may need more coals for more food</li>
                <li>
                  Whether the cover is on or off. You will want it off for frying and on for baking or roasting - use
                  more coals with the lid off
                </li>
                <li>Type of Dutch oven - use fewer coals with an aluminum Dutch oven</li>
                <li>How hot the coals are - use fewer if the coals are hotter</li>
              </ul>
              <p className="pt-2">
                Below is a chart showing the typical number of coals you will need on the top and bottom of a cast iron
                Dutch oven. Use fewer with an aluminum Dutch oven.
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div id="dutch-oven-coals-xs">
              <div className="wp-block-quote">
                <p>Number of Charcoal Briquettes</p>
                <p>
                  T = Top of Dutch Oven
                  <br />B = Bottom of Dutch Oven
                </p>
              </div>
              <table className="dutch-oven-coal-table-xs mt-4 mb-4">
                <thead>
                  {coal_headings.map((rec, idx) => (
                    <tr key={idx}>
                      <td className="p-1 dutch-oven-coal-cell-xs">
                        Temp/
                        <br />
                        Oven Size
                      </td>
                      {rec.temps.map((temps, idx) => (
                        <td key={idx} className="p-1 dutch-oven-coal-cell-xs">
                          <b>
                            {temps.f}
                            <br />
                            {temps.c}
                          </b>
                        </td>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {coals.map((rec, idx) => (
                    <tr key={idx}>
                      <td className="p-1 dutch-oven-coal-cell-xs">{rec.size}</td>
                      {rec.coals.map((coals, idx) => (
                        <td key={idx} className="p-1 dutch-oven-coal-cell-xs">
                          T:{coals.t}
                          <br />
                          B:{coals.b}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div id="dutch-oven-coals-sm">
              <div className="wp-block-quote">
                <p>Number of Charcoal Briquettes</p>
                <p>
                  Top = Top of Dutch Oven
                  <br />
                  Bottom = Bottom of Dutch Oven
                </p>
              </div>
              <table className="dutch-oven-coal-table-sm mt-4 mb-4">
                <thead>
                  {coal_headings.map((rec, idx) => (
                    <tr key={idx}>
                      <td className="p-1 dutch-oven-coal-cell-sm p-1">
                        Temp/
                        <br />
                        Oven Size
                      </td>
                      {rec.temps.map((temps, idx) => (
                        <td key={idx} className="p-1 dutch-oven-coal-cell-sm p-1">
                          <b>
                            {temps.f}
                            <br />
                            {temps.c}
                          </b>
                        </td>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {coals.map((rec, idx) => (
                    <tr key={idx}>
                      <td className="p-1 dutch-oven-coal-cell-sm p-1">{rec.size}</td>
                      {rec.coals.map((coals, idx) => (
                        <td key={idx} className="p-1 dutch-oven-coal-cell-sm p-1">
                          Top:{coals.t}
                          <br />
                          Bottom:{coals.b}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </BlogPost>
      </Container>
    </Layout>
  );
};

export default DutchOvenCookingTipsPage;
